// src/AppDevelopment.js
import React from 'react';

function AppDevelopment() {
  return (
    <section>
      <h2>Application Development</h2>
      <p>List of projects here.</p>
    </section>
  );
}

export default AppDevelopment;