// src/Contact.js
import React from 'react';

function Contact() {
  return (
    <section>
      <h2>Social Media Contact Info</h2>
      <p>Email: your-email@example.com</p>
    </section>
  );
}

export default Contact;
