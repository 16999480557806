// src/AiAndMl.js
import React from 'react';

function AiAndMl() {
  return (
    <section>
      <h2>AI and Machine Learning</h2>
      <p>List of projects here.</p>
    </section>
  );
}

export default AiAndMl;