// src/Climbing.js
import React from 'react';

function Climbing() {
  return (
    <section>
      <h2>Climbing Expeditions</h2>
      <p>List of climbs here.</p>
    </section>
  );
}

export default Climbing;
