// src/About.js
import React from 'react';

function About() {
  return (
    <section>
      <h2>About Me</h2>
      <p>This is where you write about yourself.</p>
    </section>
  );
}

export default About;
